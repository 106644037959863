<template>
    <div class="dm_subscription_page h-100">
        <!-- Navigation -->
        <header-vue></header-vue>

        <!-- Page Content -->
        <div class="container">

            <div class="row">
                <div class="col-12">
                    <div class="dm_subscription_head_wrapper d-flex justify-content-center">
                        <div class="card" style="width: 22rem;">
                            <div class="card-body text-center py-5">
                                <h2 class="card-title mt-3 mb-5">Subscription Plan</h2>
                                <h3 class="mb-5">11.99$/<span class="font-size-sm"> YEAR</span></h3>
                                <p class="card-text px-3 text-justify mb-5">upon subscribing to doggy mate users will have full access to app/website thus allowing users to view dog owner's contact information and gain the ability to communicate fully with other members who subscribed.</p>
                                <a href="/payment" class="dm_btn_med_solid">Buy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.row -->

        </div>
        <!-- /.Modals -->
        <div class="modal likes-modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Total Post Likes</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="card-header border-0 p-0">
                    <div class="d-flex mb-3" v-for="like in likes" v-bind:key="like.id">
                        <div class="">
                            <div class="dm_feed_avatar">
                                <img v-if="like.user.photo" :src="API_URL+like.user.photo.name">
                                <img v-else src="https://via.placeholder.com/50">
                            </div>
                        </div>
                        <div class="pl-3">
                            <div class="dm_feed_username">
                                <h5 class="m-0">{{ like.user.first_name }} {{ like.user.last_name }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="modal-footer">
              </div>
            </div>
          </div>
        </div>

        <div class="modal comments-modal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Total Post Comments</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="card-header border-0 p-0">
                    <div class="d-flex mb-3" v-for="(like,i) in comments" v-bind:key="like.id">
                        <div class="">
                            <div class="dm_feed_avatar">
                                <span v-if="!comments[i].user.photo || comments[i].user.photo_id === null || comments[i].user.photo.name == null">
                                    <img src="https://via.placeholder.com/50" width="15">
                                </span>
                                <img v-else :src="comments[i].user.photo.name != null ?API_URL+comments[i].user.photo.name : 'https://via.placeholder.com/50'" width="15">
                            </div>
                        </div>
                        <div class="pl-3">
                            <div class="dm_feed_username">
                                <h5 class="m-0">{{ like.user.first_name }} {{ like.user.last_name }}</h5>
                                <p class="mb-0 pb-1">{{ like.desc }}</p>
                                <p class="dm_feed_date mb-0">{{ frontEndDateFormatTime(like.created_at) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="modal-footer">
                    <div class="card-footer border-0 p-0 d-flex justify-content-between" style="width: 100%;">
                        <div class="dm_feed_avatar">
                            <img v-if="user.name" :src="API_URL+user.name">
                            <img v-else src="https://via.placeholder.com/50">
                        </div>
                        <textarea  @keydown.enter.shift.exact="newline" :class="'modal_comment_post_'+post_id" @keydown.enter.exact.prevent @keyup.enter.exact="createComment(post_id)" class="form-control border-0 shadow-none"  rows="1" placeholder="Comment on the post..."></textarea>
                    </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.container -->
        <footer-vue></footer-vue>
    </div>
</template>
<script>
import Headervue from '../partials/header-vue'
import Footervue from '../partials/footer-vue'
import {API_PROFILE_IMAGE_URL} from '../../constants'

export default {
    name: "posts",
    data: () => ({
      user:[],
      posts:[],
      breeds:[],
      likes:[],
      comments:[],
      states:[],
      search_text:'',
      breed_id:'',
      state:'',
      errors:{},
      image:'',
      title:'',
      desc:'',
      post_id:0,
      API_URL:API_PROFILE_IMAGE_URL,
      files:'',
      count:1,
      selected_comment:0,
    }),
    components: {
      'header-vue': Headervue,
      'footer-vue': Footervue
    },
    sockets: {
        post: function (data) {
            this.posts.unshift(data);
        },
        'post-likes': function(data) {
            var _this = this;
            for (var i = _this.posts.length - 1; i >= 0; i--) {
                if(_this.posts[i].id == data.post_id){
                    _this.post_id = data.post_id;
                    _this.index = i;
                    _this.posts[i] = [];
                    _this.updatePostById();
                }
            }
        },
        'post-comment': function(data) {
            var _this = this;
            for (var i = _this.posts.length - 1; i >= 0; i--) {
                if(_this.posts[i].id == data.comment.post_id){
                    _this.post_id = data.comment.post_id;
                    _this.index = i;
                    _this.posts[i] = [];
                    _this.updatePostById();
                }
            }
            if((_this.comments[0] && _this.comments[0].post_id == data.comment.post_id) || (_this.selected_comment == data.comment.post_id)){
                _this.comments.push(data.comment);
            }
        }
    },
    methods: {
        likePost: function (post_id) {
            this.$socket.emit('user:like-post', {
                post_id: post_id
            }, function (data) {
                if(data){
                    window.notify(true, 'Post Liked successfully');
                }
            });
        },
        disLikePost: function (post_id) {
            this.$socket.emit('user:dislike-post', {
                post_id: post_id
            }, function (data) {
                if(data){
                    window.notify(true, 'Post DisLiked successfully');
                }
            });
        },
        filterPost: function() {
            this.getPostsList();
        },
        triggerFileInput: function() {
            window.$('.input-file').trigger('click');
        },
        createPostData: function () {
            this.title = window.$(".input-description").val();
            this.desc = window.$(".input-description").val();
            this.files = window.$("input[type=file]")[0].files;
            // if (window.$(".input-description").val() == '') {
            //     window.notify(false, 'Please add description');
            //     return false;
            // }
            this.createPost();
        },
        viewLikesModal: function(post_id) {
            this.post_id = post_id;
            this.getLikeList();
        },
        viewCommentsModal: function(post_id) {
            if(this.comments.length == 0){
                this.selected_comment = post_id;
            }
            this.post_id = post_id;
            this.getCommentList();
        },
        openProfile: function(user_id) {
            localStorage.setItem('user_id', user_id);
            this.$router.push({ path: 'profile' })
        },
        frontEndDateFormat: function(date) {
            return window.moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY');
        },
        frontEndDateFormatTime: function(date) {
            return window.moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY hh:mm A');
        },
        async updatePostById() {
            var _this = this;
            let form = {
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getPostsByID",form);
            _this.posts[_this.index] = responce.data.data;
            _this.$forceUpdate();
            _this.post_id = 0;

        },
        async createPost() {
            let form = {
                image: this.files,
                title: this.title,
                desc: this.desc
            };
            let responce = await this.$store.dispatch("createPost", form);
            if(responce.data.status){
                window.notify(responce.data.status, 'Post added successfully');
                window.$(".emojionearea-editor").text('');
                window.$("input[type=file]").val('');
            }
        },
        async createComment(post_id) {
            let form = {
                post_id: post_id,
                desc: typeof window.$('.modal_comment_post_59').val() != 'undefined' ? window.$('.modal_comment_post_'+post_id).val() : window.$('.comment_post_'+post_id).val()
            };
            let responce = await this.$store.dispatch("createComment", form);
            if(responce.data.status){
                window.notify(responce.data.status, 'Comment added successfully');
            }
            window.$('.comment_post_'+post_id).val('');
            window.$('.modal_comment_post_'+post_id).val('');
        },
        async getPostsList() {
            let form = {
                search_text: this.search_text,
                breed: this.breed_id,
                state: this.state,
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getPostsList",form);
            this.posts = responce.data.data;
        },
        async appendPostsList() {
            var _this = this;
            let form = {
                search_text: this.search_text,
                breed: this.breed_id,
                state: this.state,
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getPostsList",form);
            if(responce.data.data.length > 0){
                for (var i = 0; i < responce.data.data.length; i++) {
                    _this.posts.push(responce.data.data[i]);
                }
                window.setTimeout(function(){
                    _this.count = 1;
                }, 5000);
            }
        },
        async getLikeList() {
            let form = {
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getLikeList",form);
            this.likes = responce.data.data.reverse();
            window.$('.likes-modal').modal('show');
        },
        async getCommentList() {
            let form = {
                post_id: this.post_id
            };
            let responce = await this.$store.dispatch("getCommentList",form);
            this.comments = responce.data.data.reverse();
            window.$('.comments-modal').modal('show');
        },
        async getBreedList() {
            let responce = await this.$store.dispatch("getBreedList");
            if (responce.status) {
                this.breeds = responce.data.data;
            }
        },
        async getStateList() {
            let responce = await this.$store.dispatch("getAllStateList");
            if (responce.status) {
                this.states = responce.data.data;
            }
        },
        async getAuthUser() {
            let responce = await this.$store.dispatch("getAuthUser");
            if (responce.status) {
                this.user = responce.data.data;
            }
        }
    },
    created() {
        var _this = this;
        this.getAuthUser();
        this.getPostsList();
        this.getBreedList();
        this.getStateList();
        window.setTimeout(function(){
            window.$(".input-description").emojioneArea({
                pickerPosition: "right",
                tonesStyle: "bullet",
            });
            var lastScrollTop = 0;
            window.$(window).scroll(function() {
                var st = window.$(this).scrollTop();
                if (st > lastScrollTop){
                    if(_this.count == 1){
                        if(_this.posts.length >= 15){
                            let buffer = 400 // # of pixels from bottom of scroll to fire your function. Can be 0

                            if (window.$(".dm_feed_page").prop('scrollHeight') - window.$(window).scrollTop() <= window.$(window).height() + buffer){
                                _this.post_id = _this.posts[_this.posts.length - 1].id;
                                _this.count = 0
                                _this.appendPostsList();


                            }
                        }
                    }

                }
            });
        }, 500);
    }
};
</script>